var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tokens.length > 0)?[(_vm.newTokens.length > 0 || _vm.removedTokens.length > 0)?_c('div',{staticClass:"my-3"},[(_vm.newTokens.length > 0)?_c('div',{staticClass:"p-3 bg-success text-white"},[_c('div',[_vm._v("There are "+_vm._s(_vm.newTokens.length)+" new translation tokens to be imported.")]),_c('div',[_vm._v("Default translations will be imported from JSON")]),_c('hr'),_c('div',[_vm._v("Here the list:")]),_c('ol',{staticClass:"list"},_vm._l((_vm.newTokens),function(token){return _c('li',[_vm._v(" "+_vm._s(token)+" ")])}),0)]):_vm._e(),(_vm.removedTokens.length > 0)?_c('div',{staticClass:"p-3 bg-danger text-white"},[_c('div',[_vm._v("There are "+_vm._s(_vm.removedTokens.length)+" translations tokens to be removed.")]),_c('hr'),_c('div',[_vm._v("Here the list:")]),_c('ol',{staticClass:"list"},_vm._l((_vm.removedTokens),function(token){return _c('li',[_vm._v(" "+_vm._s(token)+" ")])}),0)]):_vm._e(),_vm._m(0),_c('button',{staticClass:"btn btn-outline-success px-5 my-4",attrs:{"disabled":_vm.saving},on:{"click":_vm.save}},[_vm._v("Confirm the changes")])]):_c('div',{staticClass:"p-5 bg-info text-white text-center"},[_c('h3',[_vm._v("NO CHANGES IN TOKENS")]),_c('div',[_vm._v("You want to edit translation instead?")]),_c('router-link',{staticClass:"btn border border-white text-white mt-4",attrs:{"to":{
				name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
				params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
					project_id: this.$route.params.project_id
				}
			}}},[_vm._v("Edit translation ")])],1),_c('br'),_c('br')]:_vm._e(),_c('div',{staticClass:"p-5 bg-light text-center d-flex flex-column justify-content-center"},[_c('h4',[_vm._v("Insert JSON file to import")]),_c('div',{staticClass:"text-center"},[_c('input',{staticClass:"form-control d-inline-block mt-4 w-auto",attrs:{"disabled":_vm.saving,"type":"file"},on:{"change":_vm.readFile}})])]),_c('br'),_c('br'),_c('br'),_c('br')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3"},[_vm._v(" All other translation tokens will not be changed also if different in given JSON."),_c('br'),_vm._v(" Translations need to be changed into UI ")])
}]

export { render, staticRenderFns }