<template>
	<div>
		<template v-if="tokens.length > 0">

			<div v-if="newTokens.length > 0 || removedTokens.length > 0" class="my-3">
				<div class="p-3 bg-success text-white" v-if="newTokens.length > 0">
					<div>There are {{ newTokens.length }} new translation tokens to be imported.</div>
					<div>Default translations will be imported from JSON</div>
					<hr>
					<div>Here the list:</div>
					<ol class="list">
						<li v-for="token in newTokens">
							{{ token }}
						</li>
					</ol>
				</div>
				<div class="p-3 bg-danger text-white" v-if="removedTokens.length > 0">
					<div>There are {{ removedTokens.length }} translations tokens to be removed.</div>
					<hr>
					<div>Here the list:</div>
					<ol class="list">
						<li v-for="token in removedTokens">
							{{ token }}
						</li>
					</ol>
				</div>
				<div class="p-3">
					All other translation tokens will not be changed also if different in given JSON.<br/>
					Translations need to be changed into UI
				</div>
				<button class="btn btn-outline-success px-5 my-4" :disabled="saving" @click="save">Confirm the changes</button>
			</div>

			<div v-else class="p-5 bg-info text-white text-center">
				<h3>NO CHANGES IN TOKENS</h3>
				<div>You want to edit translation instead?</div>
				<router-link :to="{
					name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid,
						project_id: this.$route.params.project_id
					}
				}" class="btn border border-white text-white mt-4">Edit translation
				</router-link>
			</div>

			<br>
			<br>
		</template>

		<div class="p-5 bg-light text-center d-flex flex-column justify-content-center">
			<h4>Insert JSON file to import</h4>
			<div class="text-center">
				<input :disabled="saving" type="file" @change="readFile" class="form-control d-inline-block mt-4 w-auto">
			</div>
		</div>

		<br>
		<br>
		<br>
		<br>
	</div>
</template>

<script>
import api from '@/api'

export default {
	name: "upload",
	data: () => ({
		tokens: [],
		currentTokens: [],
		saving: false
	}),
	async mounted() {
		this.currentTokens = await api.Localizations.LocalizedContents.getJSON(this.$route.params.project_id)
	},
	methods: {
		async readFile(ev) {
			const file = ev.target.files[0];
			const reader = new FileReader();

			reader.onload = e => {
				let tmp = JSON.parse(e.target.result)

				tmp = tmp.map(
						t => {
							for (let key in t) {
								if (key !== 'ID') {
									if (Array.isArray(t[key])) {
										t[key] = t[key].join()
									}
								}
							}
							return t
						}
				)

				this.tokens = tmp

			};
			reader.readAsText(file);
		},
		async save() {
			this.saving = true
			await api.Localizations.LocalizationProjects.tokenBulkSave({
				LocalizationProjectfk: this.$route.params.project_id,
				Tokens: this.tokens,
				overwrite: false
			})
			this.saving = false
			await this.$router.push({
				name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
				params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
					project_id: this.$route.params.project_id
				}
			})
		}
	},
	computed: {
		currentTokensIds() {
			return this.currentTokens.map(t => t.ID)
		},
		tokensIds() {
			return this.tokens.map(t => t.ID)
		},
		newTokens() {
			return this.tokensIds.filter(t => !this.currentTokensIds.includes(t))
		},
		removedTokens() {
			return this.currentTokensIds.filter(t => !this.tokensIds.includes(t))
		},

	}
}
</script>

